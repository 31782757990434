<widget-header
    [name]="widgetName"
    [editable]="isEditable">

  <widget-menu
      slot="menu"
      [resource]="resource">
  </widget-menu>
</widget-header>

<div class="grid--widget-content">
  <edit-form *ngIf="(project$ | async) as project"
             [resource]="project">
    <div class="attributes-map">
      <ng-container *ngFor="let cf of customFields">
        <div class="attributes-map--key">
          {{ cf.label }}
          <attribute-help-text [attribute]="cf.key"
                               [attributeScope]="'Project'"></attribute-help-text>
        </div>
        <div class="attributes-map--value">
          <editable-attribute-field [resource]="project"
                                    [fieldName]="cf.key">
          </editable-attribute-field>
        </div>
      </ng-container>
    </div>
  </edit-form>
</div>
