<widget-header
    [name]="widgetName"
    (onRenamed)="renameWidget($event)">

  <widget-wp-graph-menu
      slot="menu"
      [resource]="resource"
      (onConfigured)="updateGraph($event)">
  </widget-wp-graph-menu>
</widget-header>

<wp-embedded-graph class='grid--widget-content -no-overflow'
                   [datasets]="datasets"
                   [chartType]="chartType">
</wp-embedded-graph>
