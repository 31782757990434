<!-- position: relative added in order for the loading indicator to be positioned correctly -->
<div class="wp-calendar--container loading-indicator--location"
     [attr.data-indicator-name]="'table'"
     style="position: relative">
    <full-calendar #ucCalendar
                   *ngIf="calendarOptions"
                   [options]="calendarOptions">
    </full-calendar>
    <div
        *ngIf="static"
        [textContent]="tooManyResultsText"
        class="wp-calendar--notification"></div>
</div>
