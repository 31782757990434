<input #dateInput
       [id]="id"
       [name]="name"
       [value]="initialDate"
       [ngClass]="classes"
       [size]="size"
       [required]="required"
       [focus]="focus"
       [disabled]="disabled"
       (click)="openOnClick()"
       (blur)="closeOnOutsideClick($event)"
       (input)="onInputChange($event)"
       type="text">
