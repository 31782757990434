<div class="op-modal--portal op-modal--macro-modal ngdialog-theme-openproject">
  <div class="op-modal--modal-container loading-indicator--location"
       data-indicator-name="modal"
       tabindex="0">
    <div class="op-modal--modal-header">
      <a class="op-modal--modal-close-button" *ngIf="showClose">
        <i
            class="icon-close"
            (click)="closeMe($event)"
            [attr.title]="text.close_popup">
        </i>
      </a>
      <h3 class="icon-context icon-attention" [textContent]="text.title"></h3>
    </div>

    <div class="ngdialog-body op-modal--modal-body">
      <form class="form -vertical" (submit)="applyAndClose($event)">
        <fieldset class="form--fieldset">
          <legend [textContent]="text.selected_type"></legend>
          <div class="form--field">
            <label
                for="selected-type"
                [textContent]="text.selected_type"
                class="form--label hidden-for-sighted">
            </label>
            <div class="form--field-container">
              <div class="form--select-container">
                <select
                    #typeSelect
                    id="selected-type"
                    tabindex="1"
                    name="selected-type"
                    (change)="selectedType = $event.target.value"
                    class="form--select">
                  <option [textContent]="text.none"
                          [selected]="!selectedType"
                          [value]=""></option>
                  <option *ngFor="let type of availableTypes"
                          [textContent]="type.name"
                          [selected]="selectedType === type.name"
                          [value]="type.name"></option>
                </select>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="form--field -wide-label">
          <div class="form--field-container -vertical">
            <label class="form--label-with-check-box">
              <div class="form--check-box-container">
                <input type="checkbox"
                       tabindex="2"
                       id="wp_button_macro_style"
                       (change)="buttonStyle = $event.target.checked"
                       [checked]="buttonStyle"
                       name="button_style" />
              </div>
              {{ text.button_style }}
            </label>
          </div>
        </div>
        <p [textContent]="text.button_style_hint"></p>
      </form>
    </div>
    <div class="op-modal--modal-footer">
      <button class="op-modal--cancel-button button"
              (click)="closeMe($event)"
              tabindex="4"
              [textContent]="text.button_cancel"
              [attr.title]="text.button_cancel">
      </button>
      <button class="op-modal--submit-button button -highlight"
              (click)="applyAndClose($event)"
              tabindex="3"
              [textContent]="text.button_save"
              [attr.title]="text.button_save">
      </button>
    </div>
  </div>
</div>
