<div id="querySelectDropdown" class="wp-query-menu--container">
  <div class="wp-query-menu--search-container">
    <div class="wp-query-menu--search-bar">
      <label for="query-title-filter"
             class="hidden-for-sighted"
             [textContent]="text.label" ></label>
      <input #wpQueryMenuSearchInput
             type="text"
             class="wp-query-menu--search-input ui-autocomplete--input ellipsis"
             name="query-title-filter"
             id="query-title-filter"
             [attr.placeholder]="text.search" />
      <i class="icon-search wp-query-menu--search-icon"></i>
    </div>
    <div #queryResultsContainer class="wp-query-menu--results-container">
      <p class="wp-query-menu--no-results-container"
         [hidden]="!noResults || loading"
         [textContent]="text.no_results"></p>
      <div class="loading-indicator -compact" *ngIf="loading">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
  </div>
</div>
