<li class="form--selected-value--container work-package--attachments--draggable-item"
    focus-within
    draggable="true"
    (dragstart)="setDragData($event)"
    [title]="text.dragHint"
    focusWithinSelector=".inplace-editing--trigger-icon">
  <span class="form--selected-value">
    <op-icon icon-classes="icon-context icon-attachment"></op-icon>
    <a
        class="work-package--attachments--filename"
        target="_blank"
        rel="noopener"
        [attr.href]="downloadPath || '#'">

      {{ attachment.fileName || attachment.customName || attachment.name }}

      <authoring class="work-package--attachments--info"
                 [createdOn]="attachment.createdAt"
                 [author]="attachment.author"
                 [showAuthorAsLink]="false"></authoring>
    </a>
  </span>
  <a
      href=""
      class="form--selected-value--remover work-package--atachments--delete-button"
      *ngIf="!!attachment.$links.delete"
      (click)="confirmRemoveAttachment($event)">
    <op-icon icon-classes="icon-delete"
             [icon-title]="text.removeFile({fileName: attachment.fileName})"></op-icon>
  </a>

  <input type="hidden" name="attachments[{{index}}][id]" value="{{attachment.id}}">
</li>
