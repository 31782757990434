<div [attr.id]="containerId"
     class="slide-toggle {{ containerClasses }}"
     [ngClass]="{ '-active': active }">
  <label class="switch">
    <input type="checkbox"
           [ngModel]="active"
           [attr.id]="inputId || undefined"
           [attr.name]="inputName || undefined"
           (ngModelChange)="onValueChanged($event)" />
    <span class="slider round"></span>
  </label>
</div>
  