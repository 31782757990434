<div class="op-modal--portal confirm-form-submit--modal  ngdialog-theme-openproject">
  <div class="op-modal--modal-container password-confirm-dialog--modal loading-indicator--location"
       data-indicator-name="modal"
       tabindex="0">
    <div class="op-modal--modal-header">
      <h3 class="icon-context icon-locked" [textContent]="text.title"></h3>
    </div>

    <div class="op-modal--modal-body">
      <form class="request-for-confirmation--form form -vertical" (submit)="confirmAndClose($event)">
        <div class="form--field -required">
          <label class="form--label"
                 for="request_for_confirmation_password"
                 [attr.aria-label]="text.field_description"
                 [textContent]="text.password">
          </label>
          <div class="form--field-container">
            <div class="form--text-field-container">
              <input type="password"
                     #passwordConfirmationField
                     [(ngModel)]="password_confirmation"
                     id="request_for_confirmation_password"
                     name="request_for_confirmation_password"
                     class="form--text-field">
            </div>
          </div>
          <div class="form--field-instructions" [textContent]="text.field_description"></div>
        </div>
      </form>
    </div>

    <div class="op-modal--modal-footer">
      <button class="confirm-form-submit--continue button"
              (click)="confirmAndClose($event)"
              [attr.title]="text.confirm_button"
              [textContent]="text.confirm_button"
              [disabled]="!passwordValuePresent()">
              [attr.title]="text.button_cancel">
      </button>
    </div>
  </div>
</div>
