<div class="op-modal--portal confirm-form-submit--modal ngdialog-theme-openproject">
  <div class="op-modal--modal-container confirm-dialog--modal loading-indicator--location"
       [ngClass]="{'-highlight -danger-zone' : dangerHighlighting }"
       data-indicator-name="modal"
       tabindex="0">
    <div class="op-modal--modal-header">
      <a class="op-modal--modal-close-button" *ngIf="showClose">
        <i
          class="icon-close"
          (click)="closeMe($event)"
          [attr.title]="text.close_popup">
        </i>
      </a>
      <h3 class="icon-context icon-attention" [textContent]="text.title"></h3>
    </div>

    <div class="ngdialog-body op-modal--modal-body">
      <p>
        <span [textContent]="text.text"></span>
        <br/>
        <span *ngFor="let data of passedData">
          <br/>
          <strong>{{data}}</strong>
        </span>
      </p>
    </div>

    <div class="op-modal--modal-footer">
      <button class="confirm-form-submit--continue button"
              [ngClass]="dangerHighlighting ? '-danger': '-highlight'"
              (click)="confirmAndClose($event)"
              [textContent]="text.button_continue"
              [attr.title]="text.button_continue">
      </button>
      <button class="confirm-form-submit--cancel button"
              (click)="closeMe($event)"
              [textContent]="text.button_cancel"
              [attr.title]="text.button_cancel">
      </button>

    </div>
  </div>
</div>
